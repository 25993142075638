import React from "react";

const herosectionData = {
  cvpath: "media/Kiran_babu_makam_UX_UI.pdf",
  cvppath: "media/UI_UX_designer_portfolio_kiran_babu_makam-c.pdf",
  name: "Kiran Makam",
  aboutMe:
    "I am an experienced UX /UI designer and developer with a great passion for creating exceptional user experiences. With more than 12 years of industry experience, I have honed my skills and expertise in various fields including finance, healthcare and real estate.",
};

function Herosection() {
  return (
    <section
      className="hero background parallax shadow-dark d-flex align-items-center"
      style={{ backgroundImage: "url(/images/hero.jpg)" }}
    >
      <div className="cta mx-auto mt-2">
        <h1 className="mt-0 mb-4">
          I’m {herosectionData.name}
          <span className="dot"></span>
        </h1>
        <p className="mb-4">{herosectionData.aboutMe}</p>

        <a href={herosectionData.cvpath} className="btn btn-default btn-lg mr-3">
          <i className="icon-cloud-download"></i>Download CV
        </a>

        <a href={herosectionData.cvppath} className="btn btn-default btn-lg mr-3">
          <i className="icon-cloud-download"></i>View Portfolio
        </a>
        
      </div>
      <div className="overlay"></div>
    </section>
  );
}

export default Herosection;
