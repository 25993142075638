import react from 'react';
import React from 'react'

const FilterData = () => {
    const menuItems = ["All Items", "Rice Item", "Cool Drinks", "Hot Drinks", "Pizza"];
    const [filter,setFilter] = react.useState("All IItems");
    return (
    <div>
        <div className="container d-flex justify-content-center g-4">
            <div className="form-inline">
                <label className='p-3'>Select Filter</label>
                <select className="form-control" onChange={(e)=>setFilter(e.target.value)}>
                    {menuItems.map((item,index) => (
                        <option value={item} key={index}>
                            {item}
                        </option>
                    ))}
                </select>
                <button className="btn btn-sm btn-primary m-3" onClick={()=>console.log(filter)}>Submit</button>
            </div>
        </div>
    </div>
    )
}

export default FilterData
