import React, { useState, useEffect } from "react";
import Portfolio2 from "../Items/Portfolio2";

const filters = [
  {
    id: 1,
    name: "All Projects",
  },
  {
    id: 2,
    name: "Branding",
  },
  {
    id: 3,
    name: "Creative",
  },
  {
    id: 4,
    name: "Design",
  },
  {
    id: 5,
    name: "Art",
  },
];

const allData = [
  {
    id: 1,
    name: "FTCI",
    image: "images/portfolio/0004.jpg",
    slug: "media/FTCI.pdf",
    disc: 'Omni Client and Employee Portal is a web application which provides client account information, account value/statements, Capital gain/ loss statement and transaction/ service status. Working in different streams like Collaborations, Performances & Reports in Fiduciary Trust International.',
    url: 'Web Application'
  },
  {
    id: 2,
    name: "FTCI Mobile App",
    image: "images/portfolio/14.jpg",
    slug: "https://apps.apple.com/us/app/fiduciary-trust-international/id1501678642?platform=iphone",
    disc: 'Omni Client face Mobile application which provides client account information, account value/statements, Capital gain/ loss statement and transaction/ service status. Working in different streams like Collaborations, Performances & Reports in Fiduciary Trust International.',
    url: 'Mobile app'
  },
  {
    id: 3,
    name: "Geico",
    image: "images/portfolio/0002.jpg",
    slug: "https://xd.adobe.com/view/3c163d67-86c1-497c-81cc-dd2f8cd64832-6945/",
    disc: 'An application has been designed to manage incidents based on customer issues. Internal teams are divided into 3 types of incidents based on problem complexity, and the team can monitor and solve the problem and update the status to the customer. Managers can add comments or upload documents if not resolved.',
    url: 'Web Application'
  },
  {
    id: 4,
    name: "Imaya",
    image: "images/portfolio/0001.jpg",
    slug: "https://www.figma.com/file/sOTVeYhaNfS7muT1fEQPyM/iMaya_Super-Admin-UI?type=design&node-id=0%3A1&mode=design&t=yWSD3Le7eXSHkfhG-1",
    disc: 'I worked for a public app that supports small business people like trainers, celebrities and doctors. these 3 category people have 3 different applications with different topics. they can create services and sell them to their customers. In my role in this project, I designed user flows, PWA designs, icons, mobile app designs, admin dashboard designs, and style guides for the total application. and I was involved in the development to implement the UI screens for the PWA application and Master Admin Portal.',
    url: 'Web Application'
  },
  {
    id: 5,
    name: "Imaya",
    image: "images/portfolio/0001.jpg",
    slug: "https://www.figma.com/proto/U797hY6qjJx5QmD1kqaWfK/iMayd_Owner_VD_V1?page-id=0%3A1&node-id=365-2266&mode=design&t=7eE8MOJnc4C5UY9C-1",
    disc: 'I worked for a public app that supports small business people like trainers, celebrities and doctors. these 3 category people have 3 different applications with different topics. they can create services and sell them to their customers. In my role in this project, I designed user flows, PWA designs, icons, mobile app designs, admin dashboard designs, and style guides for the total application. and I was involved in the development to implement the UI screens for the PWA application and Master Admin Portal.',
    url: 'PWA Application'
  },
  {
    id: 6,
    name: "Amaron",
    image: "images/portfolio/0006.jpg",
    slug: "https://xd.adobe.com/view/31840022-cc72-4986-a744-dcfa34349209-64d7/",
    disc: 'I have developed a Proof of Concept (POC) application for Amarraja Batteries, aimed at assessing the health condition of batteries. By leveraging this application, the company can effectively monitor battery health data, leading to a reduction in the usage of recycled batteries.',
    url: 'POC Web Application'
  },
  {
    id: 7,
    name: "Touch Mobiles",
    image: "images/portfolio/0003.jpg",
    slug: "https://www.figma.com/proto/U797hY6qjJx5QmD1kqaWfK/iMayd_Owner_VD_V1?page-id=0%3A1&node-id=365-2266&mode=design&t=7eE8MOJnc4C5UY9C-1",
    disc: 'Touch Mobiles is Leading Mobile reseller in AP and Telangana. we prepared E-commerce portal, and Inventory management portal. ',
    url: 'E-Commerce and Web Application'
  },
  {
    id: 8,
    name: "Zipschool",
    image: "images/portfolio/0005.jpg",
    slug: "#",
    disc: 'Multipurpose school management application. Use Neo Edify management information system to manage students, teachers, employees, courses and all the system and process related to run your institute efficiently',
    url: 'School Management Application'
  },
  {
    id: 9,
    name: "Happy Mobiles",
    image: "images/portfolio/0008.jpg",
    slug: "https://play.google.com/store/apps/details?id=com.happimobiles",
    disc: 'Developed a cutting-edge mobile application for a leading mobile retailer in AP and Telangana, catering to both iOS and Android platforms. The apps design received high praise from the client, reflecting their utmost satisfaction. ',
    url: 'Mobile application'
  },
  {
    id: 9,
    name: "Sumantra Natural Foods",
    image: "images/portfolio/0010.jpg",
    slug: "media/sumantra.pdf",
    disc: "In this project, I successfully executed the creation of a comprehensive branding package for a new organic store. My contributions encompassed designing a captivating logo, establishing brand guidelines, and formulating both do's and dont's for consistent brand representation. Additionally, I curated distinctive and user-friendly package designs tailored to various categories of products, enabling easy identification for customers within the store.Developed a cutting-edge mobile application for a leading mobile retailer in AP and Telangana, catering to both iOS and Android platforms. The apps design received high praise from the client, reflecting their utmost satisfaction.",
    url: 'Logo, Branding, Packaage Design'
  },
  {
    id: 9,
    name: "VBK",
    image: "images/portfolio/0009.jpg",
    slug: "https://vbktechsystems.com/",
    disc: "I successfully collaborated with a VBK Techsystems to create a comprehensive brand identity that included a creatively designed logo and a visually appealing website. The website was meticulously crafted using HTML5, CSS3, and bootstrap, ensuring it was fully responsive across all devices. Implementing a user-centric approach, the website was optimized for an exceptional user experience.",
    url: 'Logo, Branding, Website'
  },
  {
    id: 10,
    name: "Jayabheri Group",
    image: "images/portfolio/0013.jpg",
    slug: "http://www.jayabherigroup.com/",
    disc: "I successfully developed a cutting-edge website for a leading real estate enterprise, implementing the latest technologies like HTML5, CSS3, and bootstrap to ensure seamless responsiveness across all devices. The website's design was centered around user-centric principles, enhancing the overall user experience. Additionally, I took charge of crafting the brand's social media presence, creating captivating and consistent branding materials to bolster their online identity.I successfully collaborated with a VBK Techsystems to create a comprehensive brand identity that included a creatively designed logo and a visually appealing website. The website was meticulously crafted using HTML5, CSS3, and bootstrap, ensuring it was fully responsive across all devices. Implementing a user-centric approach, the website was optimized for an exceptional user experience.",
    url: 'Website'
  },
  {
    id: 11,
    name: "VENAR HMI",
    image: "images/portfolio/15.jpg",
    slug: "media/venar-hmi.pdf",
    disc: "I had the privilege of collaborating with Venar, a leading cold pressed oil machine manufacturer, to create a cutting-edge Human-Machine Interface (HMI) for their advanced oil pressing equipment. This project aimed to streamline machine operation, improve efficiency, and empower users with intuitive controls for an enhanced overall experience.    ",
    url: 'UI Design'
  },
];

function Portfolios2() {
  const [getAllItems] = useState(allData);
  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(3);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);

  useEffect(() => {
    setActiveFilter(filters[0].name.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= 6));
  }, [getAllItems]);

  const handleChange = (e) => {
    console.log(e.target.value);
    e.preventDefault();
    let targetFilter = e.target.value
      ? e.target.value.toLowerCase()
      : e.target.textContent.toLowerCase();
    setActiveFilter(targetFilter);
    let tempData;
    if (targetFilter === filters[0].name.toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = getAllItems.filter((data) => {
        return (
          data.category.includes(targetFilter) && data.id <= dataVisibleCount
        );
      });
    }
    setVisibleItems(tempData);
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;

    if (tempCount > getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].name.toLowerCase()) {
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        let items = getAllItems.filter((data) => {
          return data.category.includes(activeFilter) && data.id <= tempCount;
        });
        setVisibleItems(items);
      }
    }
  };

  return (
    <>

      <div className="pf-filter-wrapper mb-4">
        <select
          className="portfolio-filter-mobile"
          onChange={(e) => handleChange(e)}
        >
          {filters.map((filter) => (
            <option value={filter.name} key={filter.id}>
              {filter.name}
            </option>
          ))}
        </select>
      </div>

      <div className="row portfolio-wrapper">
        {visibleItems.map((item) => (
          <div className="col-md-6 col-sm-6 grid-item" key={item.id}>
            <Portfolio2 portfolio={item} />
          </div>
        ))}
      </div>

      {noMorePost ? null : (
        <div className="load-more text-center mt-4">
          <a
            href="#!"
            className="btn btn-default"
            onClick={(e) => handleLoadmore(e)}
          >
            <i className="fas fa-circle-notch"></i> Load more
          </a>
        </div>
      )}
    </>
  );
}

export default Portfolios2;
