import React from "react";

function Portfolio({ portfolio: { id, name, category, image, slug, disc, url } }) {
  return (
    <div className="portfolio-item">
        <div className="thumb">
          <img src={image} alt={name} />
          <div className="mask"></div>
        </div>
        <div className="details">
          <h4 className="title">{name}</h4>
          <p className="disc">{disc}</p>
          <a href={slug} className="btn btn-outline-primary"><i className="icon-link"></i> {url}</a>
        </div>
      </div>
  );
}

export default Portfolio;
