import React from "react";

const aboutData = {
  cvpath: "media/Kiran_babu_makam_UX_UI.pdf",
  image: "images/about.png",
  name: "Kiran Makam",
  location: "Hyderabad",
  phone: "+91 9177113112",
  email: "mkiran.wd@gmail.com",
  aboutMe:
    "Hi Im Kiran Makam, a highly skilled UX/UI Designer and Developer with over 12 years of experience. With a passion for creating exceptional user experiences, I have a proven track record of crafting attractive and intuitive designs. In addition to being a talented designer and CSS Expert, I am aware of the latest technologies. With experience in frameworks like Bootstrap, Angular, and React, I seamlessly bridge the gap between design and development. Throughout my career, I have collaborated with cross-functional teams to deliver innovative solutions. I thrive on complex challenges and finding creative ways to enhance user experiences.",
};

function About() {
  return (
    <div className="row">
      <div className="col-md-3">
        <img src={aboutData.image} alt={aboutData.name} />
      </div>
      <div className="col-md-9">
        <h2 className="mt-4 mt-md-0 mb-4">Hello,</h2>
        <p className="mb-0">{aboutData.aboutMe}</p>
        <div className="row my-4">
          <div className="col-md-6">
            <p className="mb-2">
              Name: <span className="text-dark">{aboutData.name}</span>
            </p>
            <p className="mb-0">
              Call: <span className="text-dark">{aboutData.phone}</span>
            </p>
          </div>
          <div className="col-md-6 mt-2 mt-md-0 mt-sm-2">
            <p className="mb-2">
              Location: <span className="text-dark">{aboutData.location}</span>
            </p>
            <p className="mb-0">
              Email: <span className="text-dark">{aboutData.email}</span>
            </p>
          </div>
        </div>
        <a href={aboutData.cvpath} className="btn btn-default mr-3">
          <i className="icon-cloud-download"></i>Download CV
        </a>
      </div>
    </div>
  );
}

export default About;
